import React from 'react';
import './blog.css';
import {Article} from '../../components';
import {blog01, blog02, blog03, blog04, blog05} from './imports';

const Blog = () => {
  return (
    <div className="gpt3__blog section__padding" id="blog">
      <div className="gpt3__blog-heading">
        <h1 className="gradient__text">A lot is happening. Find out more at our blog</h1>
      </div>
      <div className="gpt3__blog-container">
        <div className="gpt3__blog-container-groupA">
        <Article imgUrl={blog01} date="Aug 2023" title="GPT-3 and OpenAI is the future. Let us explore and dive right in!"/>
        </div>

        <div className="gpt3__blog-container-groupB">
        <Article imgUrl={blog02} date="Aug 2023" title="GPT-3 and OpenAI is the future. Let us explore and dive right in!"/>
        <Article imgUrl={blog03} date="Aug 2023" title="GPT-3 and OpenAI is the future. Let us explore and dive right in!"/>
        <Article imgUrl={blog04} date="Aug 2023" title="GPT-3 and OpenAI is the future. Let us explore and dive right in!"/>
        <Article imgUrl={blog05} date="Aug 2023" title="GPT-3 and OpenAI is the future. Let us explore and dive right in!"/>
        </div>
      </div>
    </div>
  )
}

export default Blog
