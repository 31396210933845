import React from 'react'
import './footer.css';
import gpt3Logo from '../../assets/logo.svg';

const Footer = () => {
  return (
    <div className="gpt3__footer section__padding">
      <div className="gpt3__footer-heading">
        <h1 className="gradient__text">Do you want to step into the Future? Leave no stone unturned. The future is Now!</h1>      </div>
      <div className="gpt3__footer-btn">
        <p>Request Early Access</p>
      </div>
      <div className="gpt3__footer-links">
        <div className="gpt3__footer-links_logo">
          <img src={gpt3Logo} alt="logo" />
          <p>Copyright @ Prateek Kumar Alberta, Canada All RIghts Reserved</p>
        </div>
        <div className="gpt3__footer-links_div">
          <h4>Links</h4>
          <p>Overzones</p>
          <p>Social Media</p>
          <p>Contact Us</p>
          <p>Become a Partner</p>
        </div>

        <div className="gpt3__footer-links_div">
          <h4>Company</h4>
          <p>Terms & Conditions</p>
          <p>Right to Use</p>
          <p>Privacy Policy</p>
        </div>

        <div className="gpt3__footer-links_div">
          <h4>Get in Touch</h4>
          <p>Overzones</p>
          <p>Social Media</p>
          <p>Contact Us</p>
          <p>Become a Partner</p>
        </div>


      </div>
      <div className="gpt3__footer-copyright">
        <p> © 2024 GPT-3. All Rights Reserved</p>
      </div>
    </div>
  )
}

export default Footer
